<template>
  <v-dialog persistent v-model="dialog2" max-width="500px">
    <v-card>
      <v-form>
        <v-container style="padding: 30px">
          <ValidationObserver ref="observer2" v-slot="{ validate, reset }">
            <v-row>
              <v-col cols="12">
                <h2>{{ $t("newentry") }}</h2>
              </v-col>

              <v-col cols="12" md="12">
                <v-overflow-btn
                  v-model="subcategoryId"
                  class="my-2"
                  :items="subcategories"
                  label="Nadkategorija"
                ></v-overflow-btn>
              </v-col>

              <v-col cols="12" md="12">
                <v-file-input
                  :rules="rules"
                  accept="image/png, image/jpeg, image/bmp"
                  :placeholder="$t('upload_product_picture')"
                  prepend-icon="mdi-camera"
                  label="Slika jedi"
                  v-model="item.image"
                ></v-file-input>
              </v-col>

              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <v-text-field
                    v-model="item[lang].name"
                    :label="$t('name')"
                    class="purple-input"
                    required
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="item.allergens"
                  :items="allergens"
                  attach
                  chips
                  label="Alergeni"
                  multiple
                ></v-select>
              </v-col>

              <v-col cols="12">
                <ValidationProvider v-slot="{ errors }" name="Price">
                  <v-text-field
                    v-model="item.price"
                    class="purple-input"
                    :label="$t('price')"
                    type="number"
                    @change="validate_price()"
                    :error-messages="errors"
                    min="0"
                  />
                </ValidationProvider>
              </v-col>

              <v-col cols="12">
                <ValidationProvider v-slot="{ errors }" name="Description">
                  <v-textarea
                    v-model="item[lang].description"
                    class="purple-input"
                    :label="$t('description')"
                    value
                    required
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>

              <v-col cols="12" class="text-right">
                <v-btn
                  text
                  @click="$emit('close-dialog')"
                  color="primary"
                  class="mr-0"
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn @click="add_entry" color="primary" class="mr-0">{{
                  $t("confirm")
                }}</v-btn>
              </v-col>
            </v-row>
          </ValidationObserver>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    "dialog2",
    "item",
    "lang",
    "edit_mode",
    "categoryId",
    "subcategoryId_dialog",
  ],
  data() {
    return {
      rules: [
        (value) =>
          !value ||
          value.size < 9000000 ||
          "Avatar size should be less than 9 MB!",
      ],
      subcategoryId: null,
    };
  },
  computed: {
    subcategories() {
      let subcatArray = [];
      subcatArray.push({ text: "Brez nadkategorije", value: null });
      this.$store.state.categories.forEach((cat, index) => {
        // let only subcategories of current category be chosen
        if (cat.parentId != null && cat.parentId === this.categoryId) {
          subcatArray.push({ text: cat[this.lang].name, value: cat.id });
        }
      });
      return subcatArray;
    },
    allergens: function () {
      return this.$store.state.allergens["sl"].map((a) => {
        return { text: a.label, value: a.id };
      });
    },
  },
  watch: {
    subcategoryId_dialog: function (newVal, oldVal) {
      // watch it
      this.subcategoryId = newVal;
    },
  },
  methods: {
    add_entry: function () {
      this.$refs.observer2.validate().then((result) => {
        if (result) {
          if (this.edit_mode) {
            this.$store.dispatch("UPDATE_ENTRY", {
              file: this.item.image,
              categoryId: this.categoryId,
              subcategoryId: this.subcategoryId,
              entry: this.item,
            });
          } else {
            let cat_id = this.subcategoryId
              ? this.subcategoryId
              : this.categoryId;

            let index = 0;
            this.$store.state.categories.forEach((cat) => {
              if (cat.id === cat_id) {
                for (let id in cat.entries) {
                  index = cat.entries[id].index + 1;
                }
              }
            });
            this.item.index = index;

            this.$store.state.languages.forEach((element) => {
              if (element.value != this.$store.state.lang) {
                this.item[element.value] = Object.assign(
                  {},
                  this.item[this.$store.state.lang]
                );
              }
            });
            this.$store.dispatch("SAVE_ENTRY", {
              categoryId: this.subcategoryId
                ? this.subcategoryId
                : this.categoryId,
              item: this.item,
            });
          }

          this.$notify({
            group: "foo",
            title: this.$t("success"),
            text: this.entry_edit
              ? this.$t("entry_updated")
              : this.$t("entry_added"),
            type: "success",
          });
          this.$refs.observer2.reset();

          this.$emit("close-dialog");
        }
      });
    },
    validate_price: function () {
      this.item.price = parseFloat(this.item.price).toFixed(2);
    },
  },
};
</script>
