var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[_c('v-card',[_c('v-form',[_c('v-container',{staticStyle:{"padding":"30px"}},[_c('ValidationObserver',{ref:"observer2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("newentry")))])]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-overflow-btn',{staticClass:"my-2",attrs:{"items":_vm.subcategories,"label":"Nadkategorija"},model:{value:(_vm.subcategoryId),callback:function ($$v) {_vm.subcategoryId=$$v},expression:"subcategoryId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-file-input',{attrs:{"rules":_vm.rules,"accept":"image/png, image/jpeg, image/bmp","placeholder":_vm.$t('upload_product_picture'),"prepend-icon":"mdi-camera","label":"Slika jedi"},model:{value:(_vm.item.image),callback:function ($$v) {_vm.$set(_vm.item, "image", $$v)},expression:"item.image"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"purple-input",attrs:{"label":_vm.$t('name'),"required":"","error-messages":errors},model:{value:(_vm.item[_vm.lang].name),callback:function ($$v) {_vm.$set(_vm.item[_vm.lang], "name", $$v)},expression:"item[lang].name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.allergens,"attach":"","chips":"","label":"Alergeni","multiple":""},model:{value:(_vm.item.allergens),callback:function ($$v) {_vm.$set(_vm.item, "allergens", $$v)},expression:"item.allergens"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"purple-input",attrs:{"label":_vm.$t('price'),"type":"number","error-messages":errors,"min":"0"},on:{"change":function($event){return _vm.validate_price()}},model:{value:(_vm.item.price),callback:function ($$v) {_vm.$set(_vm.item, "price", $$v)},expression:"item.price"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"purple-input",attrs:{"label":_vm.$t('description'),"value":"","required":"","error-messages":errors},model:{value:(_vm.item[_vm.lang].description),callback:function ($$v) {_vm.$set(_vm.item[_vm.lang], "description", $$v)},expression:"item[lang].description"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-0",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('close-dialog')}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-btn',{staticClass:"mr-0",attrs:{"color":"primary"},on:{"click":_vm.add_entry}},[_vm._v(_vm._s(_vm.$t("confirm")))])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }